<template>
  <b-container>
    <b-row>
      <b-col lg="6">
        <b-card>
          <b-form @submit.prevent="handleSubmit()">
            <h3>Editar información de tienda</h3>
            <div class="d-flex justify-content-start my-2">
              <base-cropper
                :model="store"
                @cropped-image="store.logo = $event"
              />
            </div>

            <b-form-group class="mb-2" label="Nombre de la tienda">
              <b-form-input v-model="store.name" required />
            </b-form-group>

            <b-form-group
              class="mb-2"
              label="¿Vendes en linea o en tu tienda fisica?"
            >
              <b-form-select
                v-model="store.category"
                :options="storeCategory"
              />
            </b-form-group>

            <b-form-group
              v-if="store.store_type !== 'supplier_type'"
              class="mb-2"
              label="Tipo de tienda"
            >
              <b-form-select v-model="store.store_type" :options="storeType" />
            </b-form-group>

            <b-form-group class="mb-2" label="¿A qué hora abres la tienda?">
              <v-select
                v-model="store.start_of_day_restocking_time"
                :options="timeArray"
                :reduce="(val) => val.type"
                label="name"
                clearable
                placeholder="Selecciona el mejor horario para ti"
              />
            </b-form-group>

            <b-form-group class="mb-2" label="¿A qué hora cierras la tienda?">
              <v-select
                v-model="store.end_of_day_restocking_time"
                :options="timeArray"
                :reduce="(val) => val.type"
                label="name"
                clearable
                placeholder="Selecciona el mejor horario para ti"
              />
            </b-form-group>

            <b-form-group
              class="mb-2"
              label="Mensaje de cobro para ventas en linea"
            >
              <b-form-input v-model="store.codi_message" required />
            </b-form-group>

            <b-form-group
              class="mb-50"
              label="¿Deseas participar en un Marketplace general?"
            >
              <b-form-checkbox
                id="in_marketplace"
                v-model="store.in_marketplace"
                class="custom-control-primary"
                name="check-button"
                switch
              >
                {{ store.in_marketplace ? "Sí" : "No" }}
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              class="mb-50"
              label="Selecciona los datos de compra que deseas solicitar a tus clientes"
            >
              <b-form-checkbox
                id="purchase_name"
                v-model="purchaseInfo.name"
                class="custom-control-primary"
                name="check-button"
              >
                Nombre
              </b-form-checkbox>
              <b-form-checkbox
                id="purchase_email"
                v-model="purchaseInfo.email"
                class="custom-control-primary"
                name="check-button"
              >
                Email
              </b-form-checkbox>
              <b-form-checkbox
                id="purchase_phone"
                v-model="purchaseInfo.phone"
                class="custom-control-primary"
                name="check-button"
              >
                Teléfono
              </b-form-checkbox>
              <b-form-checkbox
                id="purchase_delivery_date"
                v-model="purchaseInfo.delivery_date"
                class="custom-control-primary"
                name="check-button"
              >
                Fecha de entrega
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              label="Selecciona los tipos de pago que acepta esta tienda para ventas en linea"
              label-for="is-edible"
            >
              <b-form-checkbox
                id="cash"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="cash"
                switch
                @change="validatePaymentTypes"
              >
                Efectivo
              </b-form-checkbox>
              <b-form-checkbox
                id="walleat"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="walleat"
                switch
                @change="validatePaymentTypes"
              >
                Walleat Pay
              </b-form-checkbox>
              <b-form-checkbox
                id="bankcard"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="bankcard"
                switch
                @change="validatePaymentTypes"
              >
                Tarjeta bancaria
              </b-form-checkbox>
              <b-form-checkbox
                id="reward_points"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="reward_points"
                switch
                @change="validatePaymentTypes"
              >
                Puntos de recompensa
              </b-form-checkbox>
              <b-form-checkbox
                id="bank_deposit"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary"
                name="check-button"
                value="bank_deposit"
                switch
                @change="validatePaymentTypes"
              >
                Transferencia o depósito bancario
              </b-form-checkbox>
              <b-form-checkbox
                id="codi"
                v-model="acceptedPaymentTypes"
                class="custom-control-primary d-flex"
                name="check-button"
                value="codi"
                switch
                @change="validatePaymentTypes"
              >
                CoDi
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              label="Selecciona la terminal de pago que acepta esta tienda"
              label-for="terminal_type"
              v-if="acceptedPaymentTypes.includes('bankcard')"
            >
              <b-form-radio
                id="no_terminal"
                v-model="store.terminal_type"
                class="custom-control-primary"
                name="check-button"
                value="no_terminal"
              >
                No acepta terminal
              </b-form-radio>
              <b-form-radio
                id="netpay"
                v-model="store.terminal_type"
                class="custom-control-primary"
                name="check-button"
                value="netpay"
              >
                NetPay
              </b-form-radio>
              <b-form-radio
                id="other_terminal"
                v-model="store.terminal_type"
                class="custom-control-primary"
                name="check-button"
                value="other_terminal"
              >
                Otra terminal
              </b-form-radio>
            </b-form-group>

            <h4 class="mt-2">Dirección de la tienda</h4>

            <div>
              <b-form-group
                label="Selecciona los metodos de envio que acepta esta tienda"
                label-for="is-edible"
              >
                <v-select
                  v-model="store.delivery_option"
                  :options="deliveryOptions"
                  :reduce="(val) => val.value"
                  label="text"
                  clearable
                  placeholder="Selecciona el tipo de envio"
                />
              </b-form-group>

              <b-form-group
                label="Selecciona el tipo de envio"
                label-for="is-edible"
                v-if="
                  store.delivery_option === 'delivery' ||
                  store.delivery_option === 'both'
                "
              >
                <b-form-radio-group
                  id="delivery-type"
                  stacked
                  v-model="store.delivery_type"
                  class="custom-control-primary mb-50"
                  :options="deliveryTypes"
                  name="delivery-type"
                />
              </b-form-group>

              <gmap-autocomplete
                ref="googleAddress"
                class="mb-1"
                placeholder="Buscar dirección"
                :componentRestrictions="componentRestrictions"
                @place_changed="setAddressForEdit"
              />
              <b-form-group
                label="Radio de entrega (km)"
                label-for="radius"
                v-if="
                  store.address_attributes &&
                  store.address_attributes.lat &&
                  showDeliveryRadius
                "
              >
                <div
                  class="d-flex mb-2 justify-content-center align-items-center"
                >
                  <b-button
                    v-ripple
                    variant="primary"
                    class="mr-1 radius__button"
                    @click="decrementCircleRadius"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>

                  <b-form-input
                    v-model="circleRadiusKm"
                    type="range"
                    size="lg"
                    :max="30"
                    :min="0"
                    :step="1"
                    @input="updateCircleRadiusFromSlider"
                  ></b-form-input>

                  <b-button
                    v-ripple
                    variant="primary"
                    class="ml-1 radius__button"
                    @click="incrementCircleRadius"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
                <p class="text-center">{{ circleRadiusKm }} km</p>
              </b-form-group>
              <GmapMap
                v-if="showDeliveryRadius"
                ref="map"
                :center="center"
                :zoom="zoom"
                :style="`width: ${width}; height: ${height}`"
                @click="setMarker($event)"
                map-type-id="roadmap"
                :options="{
                  streetViewControl: false,
                  fullscreenControl: false,
                  restriction: {
                    latLngBounds: {
                      north: 32.718,
                      south: 14.532,
                      west: -118.503,
                      east: -86.589,
                    },
                    strictBounds: false,
                  },
                }"
              >
                <GmapMarker
                  :position="marker"
                  :clickable="true"
                  :draggable="false"
                />
                <GmapCircle
                  v-if="circle.center"
                  :center="circle.center"
                  :radius="circle.radius"
                  :editable="false"
                  :draggable="false"
                  :fillColor="`#FF0000`"
                  :fillOpacity="0.3"
                  @radius_changed="updateCircleRadius"
                  @center_changed="updateCircleCenter"
                />
              </GmapMap>
              <GmapMap
                v-else
                ref="map"
                :center="center"
                :zoom="zoom"
                :style="`width: ${width}; height: ${height}`"
                @click="setMarker($event)"
                :options="{
                  streetViewControl: false,
                  fullscreenControl: true,
                  restriction: {
                    latLngBounds: {
                      north: 32.718,
                      south: 14.532,
                      west: -118.503,
                      east: -86.589,
                    },
                    strictBounds: false,
                  },
                }"
              >
                <GmapMarker
                  :position="marker"
                  :clickable="true"
                  :draggable="false"
                />
              </GmapMap>
            </div>

            <div class="mt-2 d-flex justify-content-between">
              <b-button
                variant="outline-secondary"
                :to="{
                  name: 'establishment-sucursales',
                  params: { id: $route.params.id },
                }"
                size="lg"
              >
                Cancelar
              </b-button>
              <b-button variant="primary" size="lg" type="submit">
                Guardar
              </b-button>
            </div>
          </b-form>
        </b-card>
      </b-col>
      <b-col>
        <div class="text-center">
          <b-img
            :src="
              require('@/assets/images/illustration/pricing-Illustration.svg')
            "
            width="600"
            fluid
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// Directives
import { mapActions } from "vuex";
import vSelect from "vue-select";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { getGoogleMapsAPI } from "gmap-vue";

// Components
import AddressFormModel from "@/@core/components/AddressFormModel.vue";
import BaseCropper from "@/@core/components/BaseCropper.vue";

// Mixins
import messagesMixin from "@/@core/mixins/messagesMixin";

// Utils
import { generateTimeArray } from "@/@core/utils/time-utils";
import { deliveryOptions, deliveryTypes } from "@/@core/utils/delivery-utils";
import { STORE_TYPES } from "../../constants/store";

export default {
  components: {
    vSelect,
    VueGoogleAutocomplete,

    BaseCropper,
    AddressFormModel,
  },
  mixins: [messagesMixin],
  watch: {
    componentRestrictions: {
      handler(newRestrictions) {
        if (this.$refs.googleAddress && this.$refs.googleAddress.$refs.input) {
          this.$refs.googleAddress.$refs.input.setComponentRestrictions(
            newRestrictions
          );
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      acceptedPaymentTypes: [],
      timeArray: [],
      store: {
        establishment_id: this.$route.params.id,
        name: null,
        logo: null,
        category: null,
        store_type: null,
        codi_message: null,
        accepted_payment_types: [],
        address_attributes: null,
        end_of_day_restocking_time: null,
        start_of_day_restocking_time: null,
        delivery_option: null,
        delivery_radius: null,
        delivery_type: null,
        terminal_type: null,
        in_marketplace: false,
      },
      purchaseInfo: {
        name: false,
        email: false,
        phone: false,
        delivery_date: false,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      componentRestrictions: { country: "mx" },
      initialStore: {},
      deliveryOptions: deliveryOptions(),
      deliveryTypes: deliveryTypes(),
      storeCategory: [
        { value: "physical", text: "Tienda fisica" },
        { value: "online", text: "Tienda en línea" },
        { value: "hybrid", text: "Tienda híbrida" },
      ],
      storeType: STORE_TYPES,
      center: { lat: 19.4326077, lng: -99.133208 },
      zoom: 4,
      width: "100%",
      height: "500px",
      marker: {},
      circle: {
        center: null,
        radius: 1000,
      },
      circleRadiusKm: 0,
      addressId: null,
    };
  },
  computed: {
    showDeliveryRadius() {
      return (
        (this.store.delivery_option === "delivery" ||
          this.store.delivery_option === "both") &&
        this.store.delivery_type === "local" &&
        this.store.address_attributes
      );
    },
  },
  mounted() {
    if (this.$route.params.store_id) {
      this.fetchStore(this.$route.params.store_id).then((response) => {
        this.store = structuredClone(response);
        this.initialStore = structuredClone(response);

        this.purchaseInfo = {
          name: response.purchase_info?.name,
          email: response.purchase_info?.email,
          phone: response.purchase_info?.phone,
          delivery_date: response.purchase_info?.delivery_date,
        };

        if (response.address_attributes.formatted_address) {
          this.$refs.googleAddress.$el.children[0].value =
            response.address_attributes.formatted_address;
        } else {
          const {
            address_attributes: {
              street,
              ext_number,
              postal_code,
              city,
              state,
              country,
              formatted_address,
            },
          } = response;

          if (formatted_address) {
            this.$refs.googleAddress.$el.children[0].value = formatted_address;
          } else {
            this.$refs.googleAddress.$el.children[0].value = `${street} ${ext_number}, CP ${postal_code}, ${city}, ${state}, ${country}`;
          }
        }
        this.addressId = response.address_attributes.id;
        this.setAddressForEdit(this.store.address_attributes, true);
        this.acceptedPaymentTypes = this.store.accepted_payment_types;
        this.formatTimes();
      });
    }

    this.timeArray = generateTimeArray();
  },
  methods: {
    ...mapActions("stores", ["editStore", "fetchStore"]),
    setAddressForEdit(place, isDefault = false) {
      if (isDefault) {
        this.center = {
          lat: parseFloat(place.lat),
          lng: parseFloat(place.lng),
        };
        this.marker = {
          lat: parseFloat(place.lat),
          lng: parseFloat(place.lng),
        };

        if (Math.round(this.store.delivery_radius / 1000) === 0) {
          this.store.delivery_radius = 1000;
          this.circleRadiusKm = 1;
          this.circle.radius = 1000;
        } else {
          this.circleRadiusKm = Math.round(this.store.delivery_radius / 1000);
          this.circle.radius = this.store.delivery_radius;
        }
        this.store.address_attributes = this.getAddress(place, true);
      } else {
        this.center = {
          lat: parseFloat(place.geometry.location.lat()),
          lng: parseFloat(place.geometry.location.lng()),
        };
        this.marker = {
          lat: parseFloat(place.geometry.location.lat()),
          lng: parseFloat(place.geometry.location.lng()),
        };
        this.store.address_attributes = this.getAddress(place);
      }
      this.zoom = 16;
      this.circle.center = this.marker;
    },
    updateCircleRadius() {
      if (this.circle) {
        const circleInstance = this.$refs.circle.$circleObject;
        this.circle.radius = circleInstance.getRadius();
      }
    },

    validatePaymentTypes() {
      if (this.store.store_type === "supplier_type") {
        this.acceptedPaymentTypes = ["cash"];
        this.showErrorToast(
          "Error",
          "Si deseas agregar más metodos de pago, contactanos en Whatsapp 55 2939 7947"
        );
      }
    },

    decrementCircleRadius() {
      if (this.circleRadiusKm > 1) {
        this.circleRadiusKm = this.circleRadiusKm - 1;
        this.circle.radius = this.circleRadiusKm * 1000;
      }
    },
    incrementCircleRadius() {
      if (this.circleRadiusKm < 30) {
        this.circleRadiusKm = this.circleRadiusKm + 1;
        this.circle.radius = this.circleRadiusKm * 1000;
      }
    },
    updateCircleCenter() {
      if (this.circle) {
        const circleInstance = this.$refs.circle.$circleObject;
        this.circle.center = circleInstance.getCenter().toJSON();
      }
    },
    updateCircleRadiusFromSlider() {
      this.circle.radius = this.circleRadiusKm * 1000;
      this.circleRadiusKm = this.circle.radius / 1000;
    },
    getAddress(place, isDefault = false) {
      if (isDefault) {
        return {
          name: "",
          state: place.state,
          country: place.country,
          city: place.city,
          street: place.street,
          ext_number: place.ext_number,
          int_number: place.int_number,
          postal_code: place.postal_code,
          lat: parseFloat(place.lat),
          lng: parseFloat(place.lng),
          suburb: place.suburb,
          url: place.url,
          formatted_address: place.formatted_address || "",
          radius: this.circle.radius,
        };
      }

      const address = {
        name: "",
        state: "",
        country: "",
        city: "",
        street: "",
        ext_number: "",
        int_number: "",
        postal_code: "",
        lat: 19.4326077,
        lng: -99.133208,
        suburb: "",
        url: "",
        formatted_address: "",
        radius: this.circle.radius,
      };

      if (!place.address_components) {
        return address;
      }

      place.address_components.forEach((x) => {
        if (x.types.includes("administrative_area_level_1")) {
          address.state = x.long_name;
        }
        if (x.types.includes("country")) {
          address.country = x.long_name;
        }
        if (x.types.includes("city") || x.types.includes("locality")) {
          address.city = x.long_name;
        }
        if (x.types.includes("street") || x.types.includes("route")) {
          address.street = x.long_name;
        }
        if (
          x.types.includes("ext_number") ||
          x.types.includes("street_number")
        ) {
          address.ext_number = x.long_name;
        }
        if (x.types.includes("postal_code")) {
          address.postal_code = x.long_name;
        }
        if (
          x.types.includes("suburb") ||
          x.types.includes("sublocality_level_1")
        ) {
          address.suburb = x.long_name;
        }
      });
      address.formatted_address = place.formatted_address;
      address.lat = parseFloat(place.geometry.location.lat());
      address.lng = parseFloat(place.geometry.location.lng());
      address.url = place.url;
      return address;
    },
    setMarker(event) {
      const mapsapi = new getGoogleMapsAPI();

      const latlng = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      mapsapi.maps.Geocoder.prototype.geocode(
        { location: latlng },
        (results, status) => {
          if (status === "OK") {
            if (results[1]) {
              this.$refs.googleAddress.$el.children[0].value =
                results[1].formatted_address;
              this.address = this.getAddress(results[1]);
              const lat = parseFloat(event.latLng.lat());
              const lng = parseFloat(event.latLng.lng());
              this.address.google_maps_url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
              this.marker = {
                lat,
                lng,
              };
              this.circle.center = this.marker;
              this.circle.radius = this.circleRadiusKm * 1000;
              this.$emit("setAddress", this.address);
              this.zoom = 8;
              this.store.address_attributes = this.address;
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Introduzca la direccion manualmente",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          }
        }
      );
    },

    formatTimes() {
      if (this.store.end_of_day_restocking_time) {
        // Extract hours and minutes from the string
        const hoursAndMinutes = this.store.end_of_day_restocking_time.slice(
          11,
          19
        );
        this.store.end_of_day_restocking_time = hoursAndMinutes;
        this.initialStore.end_of_day_restocking_time = hoursAndMinutes;
      }

      if (this.store.start_of_day_restocking_time) {
        // Extract hours and minutes from the string
        const hoursAndMinutes = this.store.start_of_day_restocking_time.slice(
          11,
          19
        );
        this.store.start_of_day_restocking_time = hoursAndMinutes;
        this.initialStore.start_of_day_restocking_time = hoursAndMinutes;
      }
    },
    setStore() {
      const address = this.store.address_attributes;

      address.id = this.addressId;

      return {
        name: this.store.name,
        establishment_id: this.$route.params.id,
        logo: this.store.logo,
        category: this.store.category,
        store_type: this.store.store_type,
        address_attributes: address,
        codi_message: this.store.codi_message,
        accepted_payment_types: this.acceptedPaymentTypes,
        end_of_day_restocking_time: this.store.end_of_day_restocking_time,
        start_of_day_restocking_time: this.store.start_of_day_restocking_time,
        delivery_option: this.store.delivery_option,
        delivery_radius: this.circle.radius,
        delivery_type: this.store.delivery_type,
        terminal_type: this.store.terminal_type,
        in_marketplace: this.store.in_marketplace,
        purchase_info: this.purchaseInfo,
      };
    },

    isEqual(initialStore, newStore) {
      if (
        initialStore.name === newStore.name &&
        initialStore.logo === newStore.logo &&
        initialStore.category === newStore.category &&
        initialStore.store_type === newStore.store_type &&
        initialStore.address_attributes === newStore.address_attributes &&
        initialStore.codi_message === newStore.codi_message &&
        initialStore.accepted_payment_types ===
          newStore.accepted_payment_types &&
        initialStore.end_of_day_restocking_time ===
          newStore.end_of_day_restocking_time &&
        initialStore.start_of_day_restocking_time ===
          newStore.start_of_day_restocking_time &&
        initialStore.delivery_option === newStore.delivery_option &&
        initialStore.delivery_radius === newStore.delivery_radius &&
        initialStore.delivery_type === newStore.delivery_type
      ) {
        return true;
      }

      return false;
    },

    handleSubmit() {
      if (
        !this.store.address_attributes ||
        !this.store.address_attributes.lat ||
        !this.store.address_attributes.lng
      ) {
        this.showErrorToast(
          "Error",
          "Por favor selecciona una dirección en el mapa"
        );

        return;
      }

      let store = this.setStore();

      if (this.isEqual(this.initialStore, store)) {
        this.showSuccessToast("Exito", "No se realizaron cambios");

        return;
      }

      this.$swal({
        title: "¿Estas seguro de que deseas guardar los cambios?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, guardar",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            title: "Guardando cambios...",
            didOpen: () => {
              this.$swal.showLoading();
            },
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });

          this.editStore({ id: this.$route.params.store_id, store })
            .then((res) => {
              this.showSuccessToast("Tienda editada correctamente");

              if (this.userData.role_name === "establishment_supplier") {
                this.$router.push({
                  name: "establishment-view",
                  params: { id: this.userData.supplier_info?.establishment_id },
                });
                return;
              }

              this.$router.push({
                name: "establishment-sucursales",
                params: { id: this.$route.params.id },
              });
            })
            .catch((error) => {
              this.handleFetchError(
                error.response.data.message,
                "Error al editar la tienda"
              );
            })
            .finally(() => {
              this.$swal.close();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.custom-control {
  margin-bottom: 0.5rem;
}

.radius__button {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 0;
}

.pac-target-input {
  color: #495057;
  background-color: #fff;
  border: 1px solid #404656;

  padding: 0.438rem 1rem;
  border-radius: 0.357rem;
  width: 100%;
}

.dark-layout {
  .pac-target-input {
    color: #b4b7bd;
    background-color: #283046;
    border: 1px solid #404656;
  }
}
</style>
