export const deliveryTypes = () => {
  return [
    { text: "Local", value: "local" },
    { text: "Nacional", value: "national" },
  ]
}

export const deliveryOptions = () => {
  return [
    { value: "pickup", text: "Recoger en tienda" },
    { value: "delivery", text: "Envíos únicamente" },
    { value: "both", text: "Envíos y recoger en tienda" },
    { value: "not_applicable", text: "No aplica" },
  ]
}
